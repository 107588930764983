@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: Klarheit;
    src: url('/fonts/Klarheit_Grotesk_Regular.otf') format('opentype'), url('/fonts/Klarheit_Grotesk_Regular.woff') format('woff'),
      url('/fonts/Klarheit_Grotesk_Regular.woff2') format('woff2'), url('/fonts/Klarheit_Grotesk_Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }



body {
    font-family: Klarheit, sans-serif;
    margin: 0;
  
    background: var(--background-color);
  
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  span {
    margin: 0;
  }
  
  * {
    box-sizing: border-box;
  }